import React, {useCallback, useEffect, useState} from 'react';
import {GlobalButtonFinal, ModalsWrapper, ModalWindow} from "../GlobalStyles";
import {getCookie} from "cookies-next";
import {faICursor} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { FormattedMessage } from 'react-intl';
import messages from '../../../../src/messages';



export default function DialogRename ({productRenameUUID,renameProductName,
                                      toggleRename,reloadProducts
                                      }) {

    const [loadingRename, setLoadingRename] = useState(false);
    const [renameShow, setRenameShow] = useState(false);
    const [renameTransitionShow, setRenameTransitionShow] = useState(false);

    const changeRenameShow = (variable) => {

        if(variable === true){

            setRenameShow(variable);
            setTimeout(function() {
                setRenameTransitionShow(true);
            },1);




        } else{


            setRenameTransitionShow(false);
            setTimeout(function() {
                setRenameShow(variable);
            },500);



        }

    };

    const [nameProduct, setNameProduct] = useState<string>(undefined);
    const handleNameProduct = function (e: React.FormEvent<HTMLInputElement>) {
        setNameProduct(e.currentTarget.value)
    }

    const [checkedUUIDZ, setCheckedUUIDZ] = useState(null);

    const renameProduct = () => {

        setLoadingRename(true);
        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH+"products/"+productRenameUUID, {
            method: 'PUT',
            headers: {
                'Content-type': 'application/json',
                'Authorization': "Bearer "+ getCookie('userTeam'), // notice the Bearer before your token
            },
            body: JSON.stringify(    {
                "name" : nameProduct
            }),
        })
            .then(response => response.json())
            .then(function(data){


                if(data.status === 200){
                     changeRenameShow(false);
                    reloadProducts();

                }

            });

    };

    useEffect(() => {
        setNameProduct(renameProductName);
    }, [renameProductName]);

    const [showRenameDialog, setShowRenameDialog] = useState(false);
    const toggleRenameInside = useCallback((): void => {
        if(showRenameDialog){
            changeRenameShow(false);
        }else{
            setCheckedUUIDZ(null);
            changeRenameShow(true);
        }


    }, []);
    useEffect(() => {
        toggleRename && toggleRename(toggleRenameInside);
    }, [toggleRename, toggleRenameInside]);



    const closeModalOutsideClick = (e) => {

        if( e.target.getAttribute("data-closemodal") === "true"){
            changeRenameShow(false);
        }
    }




    return (
        <>




            <ModalsWrapper className={`renameCheck  ${renameTransitionShow ? 'showTransition' : 'hideTransition'} ${!renameShow ? 'hiddeComplete' : ''}`}
                           data-closemodal={true}
                           onClick={(e)=> closeModalOutsideClick(e)}
            >


                <ModalWindow
                    className={"center modal_dialog_small"}
                    id={'renameProductDialog'} >
                    <div className={'modal-header'}>
                        <h4>      <FormattedMessage {...messages.products_modal_rename_title}></FormattedMessage> </h4>

                    </div>


                    <div className={'modal-content shareProduct'} style={{ display: "block" }}>

                        <br />


                        <input type="text"
                               style={{ width: "79%", display: "inline-block" }}
                               value={nameProduct} className={"input-md form-control inputRounded"}
                               id={"nameProductNew"}
                               placeholder={""} onChange={handleNameProduct} />




                        <div className={"modal-buttons"}>
                            <GlobalButtonFinal
                                className={"borderBlue btn-circle"}
                                onClick={() => changeRenameShow(false)}>
                                <FormattedMessage {...messages.products_modal_rename_button_cancel}></FormattedMessage>
                            </GlobalButtonFinal>
                            <GlobalButtonFinal
                                               className={"backgroundBlue btn-circle"} data-uuid={productRenameUUID}
                                               onClick={() => renameProduct()}>
                                <FontAwesomeIcon icon={faICursor} />     <FormattedMessage {...messages.products_modal_rename_button_rename}></FormattedMessage>
                            </GlobalButtonFinal>
                        </div>


                    </div>
                </ModalWindow>


            </ModalsWrapper>


        </>
    )
}