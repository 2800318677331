import * as Sentry from '@sentry/nextjs';
import { getCookie } from 'cookies-next';

export const checkAgreement = (token) => {

    fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH+"user/current", {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
            'Authorization': "Bearer "+ token, // notice the Bearer before your token
        },
    })
        .then(response => response.json())
        .then(function(data){
            if(data.message !== undefined){
                Sentry.captureException("ser/current"+": "+ data.message + " : " + getCookie("userMail"));
            }

            if(data.agreed_terms_version){
                loadAgreementsDocument(data.agreed_terms_version,token);

            } else {
                window.open("/terms-and-conditions", "_self");

            }

        });

};


const loadAgreementsDocument = (agreedVersion,token) => {


    fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH+"articles/TERMS_AND_CONDITIONS_OF_USE", {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
            'Authorization': "Bearer "+ token, // notice the Bearer before your token
        },
    })
        .then(response => response.json())
        .then(function(data){
            if(data.message !== undefined){
                Sentry.captureException("articles/TERMS_AND_CONDITIONS_OF_US"+": "+ data.message + " : " + getCookie("userMail"));
                //   triggerWarning(data.message);
            } else {

                if(!agreedVersion){
                    window.open("/terms-and-conditions", "_self");
                } else {

                    if(data.version !== agreedVersion){
                        window.open("/terms-and-conditions", "_self");
                    }

                }

            }


        });

};

